import { Component, } from '@angular/core';

@Component({
  selector: 'app-sectionApp',  
  templateUrl: './sectionApp.component.html',
  styleUrl: './sectionApp.component.scss'
})
export class SectionAppComponent {  
  
  openDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'block';
    }
  }

  closeDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'none';
    }
  }
}
