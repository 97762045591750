import { Component, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-sectionVentajas',  
  templateUrl: './sectionVentajas.component.html',
  styleUrl: './sectionVentajas.component.scss'
})
export class SectionVentajasComponent {
  language: string;
  contactsRoute: string[];
  languageFormControl: UntypedFormControl= new UntypedFormControl();

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService,
    public languageService: LanguageService
  ) { }

  ngOnInit(): void {

    this.languageFormControl.valueChanges.subscribe(val => this.languageService.changeLanguage(val))

    this.languageFormControl.setValue(this.languageService.language)
    this.route.params.subscribe(params => {
      this.language = params['language'] || 'es';
      this.updateContactRoute();
    });
  }
  navigateToRoute(): void {
    this.router.navigate(this.contactsRoute);    
    this.scrollToTop();
  }
    
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });    
  }
  
  updateContactRoute(): void {
    if (this.language === 'es') {
      this.contactsRoute = ['/', this.language, 'nosotros'];
    } 
    if  (this.language === 'en') {
      this.contactsRoute = ['/', this.language, 'about-Us'];
    }    
  }

}