<section class="section about"
	id='aboutUs'>
	<div class="banner">
		<div *ngFor='let slide of "Contactview" |translate; let i = index'
			class="container d-flex">
			<div class="col-md-4 banner-left"></div>
			<div class="col-md-6 banner-section">
				<h2 [innerHTML]="slide.title | translate"></h2>
				<img class="img-qr mt-4"
					src="../../../../assets/images/soloQR.png"
					alt>
				<a href="https://api.whatsapp.com/send/?phone=59161597086&text&type=phone_number&app_absent=0"
					target="_blank">
					<p [innerHTML]='"whatsappNumber2" | translate'
						class="mt-2 whatsappnumber">
					</p>
				</a>
				<a (click)="openDialog()"
					class="play-store-button">
					<img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
						alt="Get it on Google Play">
				</a>
				<div class="dialog-overlay"
					id="dialog">
					<div class="dialog-content">
						<h2>¡Muy Pronto!</h2>
						<p>
							Actualmente estamos realizando mejoras a nuestra app para que puedas tener una experiencia completa.
						</p>
						<p>
							Mantente atento a nuestras redes sociales y entérate de nuestras novedades.
						</p>
						<p>
							<strong>Equipo Presta Ya</strong>
						</p>


						<!-- Redes sociales -->
						<div class="footer-social2">
							<a href="https://www.facebook.com/prestaYa.bo?mibextid=ZbWKwL"
								target="_blank">
								<i class="fab fa-facebook"></i>
							</a>
							<a href="https://www.linkedin.com/company/prestayabo/"
								target="_blank">
								<i class="fab fa-linkedin"></i>
							</a>
							<a href="https://www.instagram.com/prestaya.bo?igsh=bzlvOXZ2NDQydGd0"
								target="_blank">
								<i class="fab fa-instagram"></i>
							</a>
						</div>


						<!-- Botón de cerrar -->
						<button (click)="closeDialog()">Cerrar</button>
					</div>
				</div>


			</div>
		</div>
	</div>
	<div class="container">
		<app-sectionNosotros></app-sectionNosotros>
	</div>
	<!-- [title2]='"Banner.visionTittle" | translate'
	[subtitle2]='"Banner.visionContent" | translate' -->
	<div class="container">
		<mision-vision [title]='"Banner.visionTittle" | translate'
			[subtitle]='"vision"'
			[imagePosition]="'right'"
			[subtitle]='"Banner.visionContent" | translate'
			[imageUrl]='"Banner.visionUrl" | translate'
			data-aos="fade-up">
		</mision-vision>
	</div>
	<div class="container">
		<mision-vision [title]='"Banner.misionTittle" | translate'
			[subtitle]='"Banner.misionContent" | translate'
			[imagePosition]="imagePosition"
			[subtitle]='"Banner.misionContent" | translate'
			[imageUrl]='"Banner.misionUrl" | translate'
			[ngClass]="{'image-left': isMobile, 'image-right': !isMobile}"
			data-aos="fade-up">
		</mision-vision>
	</div>
	<div class="container">
		<app-contact></app-contact>
	</div>
	<app-sectionEmpresas></app-sectionEmpresas>
	<div class="container-section-app">
		<app-sectionApp></app-sectionApp>
	</div>
</section>
