import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { MyChartComponent } from '../../general/graph/my-chart.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prestarse',
  templateUrl: './prestarse.component.html',
  styleUrls: ['./prestarse.component.scss']
})
export class PrestarseViewComponent {  
  private timeouts: any[] = [];

  responsiveMenuVisible: boolean = false;
  showContainerCalc: boolean = false;
  selectedQuestion: any
  isMobile: boolean = false;
  imagePosition: string = 'left';
  monto: number = 5000;
  plazo: number = 1;
  description: string = '';
  totalLend: number = Number((this.monto * 0.03).toFixed(2));
  totalPercentage: number;
  totalAmount: number;
  montoError: string = '';
  plazoError: string = '';
  isValid: boolean = false;
  timeString: string = ' ';
  percentage: number;
  @ViewChild(MyChartComponent) myChartComponent: MyChartComponent;
  // @ViewChild(MyChartComponent) myChartComponent: MyChartComponentLend;
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkViewport();
  }
  constructor(    
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.checkViewport();
  }
  ngOnInit() {
    this.checkViewport();
  }
  
  checkViewport() {
    this.isMobile = window.innerWidth <= 760;
    this.imagePosition = this.isMobile ? 'right' : 'left';
  }

  
  @ViewChild('popupDialog') popupDialog: ElementRef<HTMLDialogElement>;
  @ViewChild('tableContainer') tableContainer: ElementRef;

  openPopup() {
    this.popupDialog.nativeElement.showModal();
  }
  ngAfterViewInit() {
    if (this.tableContainer) {
      setTimeout(() => {
        this.tableContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }, 500); // Espera medio segundo para asegurarse de que el DOM está listo
    }
  }
  closePopup() {
    this.popupDialog.nativeElement.close();
  }

  openDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'block';
    }
  }

  closeDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'none';
    }
  }  
  aux = Number((this.monto * 0.03).toFixed(2))
  
  calculateLend() {
    this.timeString = 'meses'    
    this.totalLend = Number((this.monto * 0.03  + this.monto / this.plazo).toFixed(2));
    this.totalPercentage = this.plazo * 3 ;
    this.totalAmount = Number((this.totalLend * this.plazo).toFixed(2));    
    setTimeout(() => {
      this.myChartComponent.total = this.totalLend;
      this.myChartComponent.percentage = this.totalPercentage;
      this.myChartComponent.updateChart();
    }, 100);    
  }  
  scroll(el) {    
    setTimeout(() => {
      const element = document.getElementById(el);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      this.calculateLend()
    }, 500);
  }

  calculateAndScroll() {        
    this.showContainerCalc = true;        
    if (this.tableContainer && this.tableContainer.nativeElement) {
      this.calculateLend();
      this.tableContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }
    this.scroll('table-container2');    
  }

  get sliderBackgroundMonto() {
    const percentage = ((this.monto - 100) / (14000 - 100)) * 100;
    return `linear-gradient(to right, rgba(22, 147, 111, 1) ${percentage}%, #ddd ${percentage}%)`;
  }

  get sliderBackgroundPlazo() {
    const percentage = ((this.plazo - 1) / (24 - 1)) * 100;
    return `linear-gradient(to right, rgba(22, 147, 111, 1) ${percentage}%, #ddd ${percentage}%)`;
  }
  ngOnDestroy(): void {
    if (this.myChartComponent) {
      this.myChartComponent.destroyChart();
      console.log('Gráfico destruido.');
    }

    this.timeouts.forEach(timeout => clearTimeout(timeout));
    this.timeouts = [];

    console.log('Recursos del componente PrestarViewComponent liberados.');
  }
}
