<div class="section-app">
	<h1>
		{{"section.AppMobile"|translate}}
	</h1>
	<p class="text-section">
		{{"section.AppMobileText"|translate}}
	</p>
	<div class="container-download">
		<a (click)="openDialog()"
			class="play-store-button">
			<img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
				alt="Get it on Google Play">
		</a>
		<div class="dialog-overlay"
			id="dialog">
			<div class="dialog-content">
				<h2>¡Muy Pronto!</h2>
				<p>
					Actualmente estamos realizando mejoras a nuestra app para que puedas tener una experiencia completa.
				</p>
				<p>
					Mantente atento a nuestras redes sociales y entérate de nuestras novedades.
				</p>
				<p>
					<strong>Equipo Presta Ya</strong>
				</p>



				<!-- Redes sociales -->
				<div class="footer-social2">
					<a href="https://www.facebook.com/prestaYa.bo?mibextid=ZbWKwL"
						target="_blank">
						<i class="fab fa-facebook"></i>
					</a>
					<a href="https://www.linkedin.com/company/prestayabo/"
						target="_blank">
						<i class="fab fa-linkedin"></i>
					</a>
					<a href="https://www.instagram.com/prestaya.bo?igsh=bzlvOXZ2NDQydGd0"
						target="_blank">
						<i class="fab fa-instagram"></i>
					</a>
				</div>



				<!-- Botón de cerrar -->
				<button (click)="closeDialog()">Cerrar</button>
			</div>
		</div>
		<!-- <a href="#"
			class="play-store-button">
			<img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
				alt="Get it on Google Play">
		</a> -->
	</div>
</div>
