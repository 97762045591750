<div class="detail-card">
	<!-- <div class="card-header"></div>
	<div class="card-body">
		<h2>
			{{"Generico.Detail" | translate}}
		</h2>
		<div class="highlight">Bs. {{total}}</div>
		<div class="text-cards">
			<p class="strong-text d-flex justify-content-start align-items-center">
				{{"Lend.Amount" | translate}}
				<span class="ml-auto">Bs. {{amount}}</span>
			</p>
			<p class="strong-text d-flex justify-content-start align-items-center">
				{{"Lend.Tasa" | translate}}
				<span class="ml-auto">{{percentage}} %</span>
			</p>
			<p class="d-flex justify-content-start align-items-center">
				{{"Lend.Time" | translate}}
				<span class="ml-auto">{{time}} Meses</span>
			</p>
			<p class="strong-text d-flex justify-content-start align-items-center">
				{{"Lend.Cuota" | translate}}
				<span class="ml-auto">Bs. {{totalAmount}}</span>
			</p>
		</div>
	</div> -->
	<div class="card-container">
		<div class="card">
			<p [innerHTML]='"Generico.Detail" | translate'></p>
			<h1>{{totalAmount}} Bs</h1>
		</div>
		<div class="details-card">
			<div class="detail-item">
				<span [innerHTML]='"Lend.Cuota"  | translate'></span>
				<span>{{timeString}}</span>
			</div>
			<div class="detail-item">
				<span [innerHTML]='"Lend.Amount" | translate'></span>
				<span>{{amount}} Bs</span>
			</div>
			<div class="detail-item">
				<span [innerHTML]='"Lend.Tasa" | translate'></span>
				<span>{{percentage}}%</span>
			</div>
			<div class="detail-item">
				<span [innerHTML]='"Lend.Time"| translate'></span>
				<span>{{time}} Meses</span>
			</div>
		</div>
		<a (click)='navigateToRoute()'
			target="_blank"
			class="register-btn">
			{{"Generico.Registro" | translate}}
		</a>
	</div>







	<!-- <div class="card-footer">
		<button (click)="openPopup()"
			class="btn registro-btn">
			{{"Generico.Registro" | translate}}
		</button>
		<dialog #popupDialog>
			<div class="modal-header">
				<h4 class="modal-title text-center">Muy pronto</h4>
				<button (click)="closePopup()"
					type="button"
					class="close"
					aria-label="Close">
					<span aria-hidden="true"
						class="green-icon">
						&times;
					</span>
				</button>
			</div>
			<div class="modal-body">
				<img src='assets/images/cuadradomini-sf.png'
					alt="Imagen emergente"
					class="popup-image">
				<p>
					Se habilitarán los registros
				</p>
			</div>
			<div class="modal-footer">
				<button (click)="closePopup()"
					type="button"
					class="btn btn-outline-dark green-button">
					Cerrar
				</button>
			</div>
		</dialog>
	</div> -->
</div>
