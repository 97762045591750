import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MyChartComponent } from '../../general/graph/my-chart.component';

@Component({
  selector: 'app-prestar',
  templateUrl: './prestar.component.html',
  styleUrls: ['./prestar.component.scss']
})
export class PrestarViewComponent {  
  private timeouts: any[] = [];

  showContainerCalc: boolean = false;
  responsiveMenuVisible: boolean = false;
  selectedQuestion: any
  isMobile: boolean = false;
  imagePosition: string = 'left';
  monto: number;
  plazo: number = 1;
  totalInvest: number;
  totalgain: number;
  percentageTotal: number;
  showTableInvest = true;
  montoError: string = '';
  plazoError: string = '';
  isValid: boolean = false;
  @ViewChild(MyChartComponent) myChartComponent: MyChartComponent;
  total: any;
amount: any = 10000;
  percentage: any;
  time: any;
  totalAmount: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public analyticsService: AnalyticsService
  ) {}

  get sliderBackgroundMonto() {
    const percentage = ((this.monto - 100) / (14000 - 100)) * 100;
    return `linear-gradient(to right, rgba(1, 50, 55, 1) ${percentage}%, #ddd ${percentage}%)`;
  }

  get sliderBackgroundPlazo() {
    const percentage = ((this.plazo - 1) / (24 - 1)) * 100;
    return `linear-gradient(to right, rgba(1, 50, 55, 1) ${percentage}%, #ddd ${percentage}%)`;
  }

  updateSliderColor() {
    this.montoError = '';
    this.plazoError = '';
    this.monto = this.monto;
    this.plazo = this.plazo;
  }
  auxMonto = this.amount*0.2
  calculate() {
    this.totalInvest = Number(
      ((this.monto * 0.02 + this.monto / this.plazo).toFixed(2))
    );

    this.totalgain =Number((this.totalInvest).toFixed(2));
    this.percentageTotal = this.plazo * 2;
    setTimeout(() => {
      this.myChartComponent.total = this.totalgain;
      this.myChartComponent.percentage = this.percentageTotal;
      this.myChartComponent.updateChart();
    }, 100);
  }

  calculateAndScroll() {      
    this.showContainerCalc = true;
    const tableContainer = document.getElementById('table-container');
    if (tableContainer) {
      this.calculate();
      tableContainer.scrollIntoView({ behavior: 'smooth' });
    }
    this.scroll('table-container');  
  }
  
  scroll(el) {    
    setTimeout(() => {
      const element = document.getElementById(el);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      this.calculate()
    }, 500);
  }
  ngOnDestroy(): void {
    if (this.myChartComponent) {
      this.myChartComponent.destroyChart();
      console.log('Gráfico destruido.');
    }

    this.timeouts.forEach(timeout => clearTimeout(timeout));
    this.timeouts = [];

    console.log('Recursos del componente PrestarViewComponent liberados.');
  }
}
