import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Component({
  selector: 'app-aboutUs',
  templateUrl: './aboutUs.component.html',
  styleUrls: ['./aboutUs.component.scss']
})
export class AboutUsComponent {  

  responsiveMenuVisible: boolean = false;
  selectedQuestion: any
  isMobile: boolean = false;
  imagePosition: string = 'left';
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkViewport();
  }
  constructor() {
    this.checkViewport();
  }
  ngOnInit() {
    this.checkViewport();
  }
  
  checkViewport() {
    this.isMobile = window.innerWidth <= 760;
    this.imagePosition = this.isMobile ? 'right' : 'left';
  }

  
  @ViewChild('popupDialog') popupDialog: ElementRef<HTMLDialogElement>;

  openPopup() {
    this.popupDialog.nativeElement.showModal();
  }

  closePopup() {
    this.popupDialog.nativeElement.close();
  }

  openDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'block';
    }
  }

  closeDialog() {
    const dialog = document.getElementById('dialog');
    if (dialog) {
      dialog.style.display = 'none';
    }
  }
}
