import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MyChartComponent } from '../../general/graph/my-chart.component';

@Component({
  selector: 'app-lend-section',
  templateUrl: './lend-section.component.html',
  styleUrls: ['./lend-section.component.scss']
})
export class LendSectionComponent implements OnInit {
  monto: number = 100;
  plazo: number = 1;
  description: string = '';
  totalLend: number;
  totalPercentage: number;
  totalAmount: number;
  montoError: string = '';
  plazoError: string = '';
  isValid: boolean = false;
  timeString: string = ' ';
  percentage: number;

  get sliderBackgroundMonto() {
    const percentage = (this.monto - 100) / (14000 - 100) * 100;
    return `linear-gradient(to right, #16936F ${percentage}%, #ddd ${percentage}%)`;
  }

  get sliderBackgroundPlazo() {
    const percentage = (this.plazo - 1) / (24 - 1) * 100;
    return `linear-gradient(to right, #16936F ${percentage}%, #ddd ${percentage}%)`;
  }

  updateSliderColor() {
    this.montoError = '';
    this.plazoError = '';
    this.monto =  this.monto;
    this.plazo = this.plazo;
  }
  private validatepercetage(){
    if (this.monto <= 7000 )
      this.percentage = 3      
    else if (this.monto > 7000 && this.monto<= 10000)
      return this.percentage = 2.5;    
    else if (this.monto > 10000)
      this.percentage = 2  
  }

  validateInputs(): boolean {
    this.isValid = true;
    if (this.monto < 100 || this.monto > 14000) {
      this.montoError = 'El monto debe estar entre 100 y 14000.';      
      this.isValid = false;
    }
    else{
      this.montoError = '';
    }
    if (this.plazo < 1 || this.plazo > 24) {
      this.plazoError = 'El plazo debe estar entre 1 y 24.';      
      this.isValid = false;
    }            
    return this.isValid;
  }
  
  calculateLend() {
    this.timeString = 'meses'
    this.validateInputs();    
    this.validatepercetage();
    if(this.isValid){  
      this.totalLend = Number((((this.monto * 0.03) + (this.monto / this.plazo)).toFixed(2)));
      this.totalPercentage = this.percentage;
      this.totalAmount = this.totalLend * this.plazo;    
    }
  }
  
  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
  }
  
}
