<section class="section about"
	id='lends'>
	<div class="container"
		id="calc">
		<div class="green-bar"></div>
		<h2 [innerHTML]='"Lend.Title2" | translate'
			class="text-center mb-5"></h2>
		<div class="container-lend">
			<div class="col-md-6 ">
				<div class="container-labels mb-2">
					<div class="form-group">
						<label for="time">
							{{"Generico.Monto" | translate}}
							<div class="input-section">
								<input [(ngModel)]="monto"
									(input)="validateInputs()"
									type="number"
									min="100"
									max="14000"
									class="editable">
								<span class="currency">Bs</span>
							</div>
						</label>
					</div>
					<div class="flex items-center mb-4">
						<input [(ngModel)]="monto"
							[style.background]="sliderBackgroundMonto"
							(input)="updateSliderColor(); validateInputs()"
							id="range1"
							type="range"
							min="100"
							max="14000"
							step="100"
							class="w-full h-2 appearance-none cursor-pointer">
					</div>
					<div *ngIf="montoError"
						class="text-red-500">
						<p class="alert-text">{{ montoError }}</p>
					</div>
					<!-- <div class="input-range-label mb-2">
							<span>100</span>
							<span>14000</span>
						</div> -->
					<div class="form-group">
						<label for="time">
							{{"Generico.Tiempo" | translate}}
							<div class="input-section">
								<input [(ngModel)]="plazo"
									(input)="validateInputs()"
									type="number"
									min="100"
									max="14000"
									class="editable">
								<span class="currency">Meses</span>
							</div>
						</label>
					</div>
					<div class="flex items-center ">
						<input [(ngModel)]="plazo"
							[style.background]="sliderBackgroundPlazo"
							(input)="updateSliderColor(); validateInputs()"
							id="range2"
							type="range"
							min="1"
							max="24"
							step="1"
							class="w-full h-2 appearance-none cursor-pointer">
					</div>
					<div *ngIf="plazoError"
						class="text-red-500">
						<p class="alert-text">{{ plazoError }}</p>
					</div>
				</div>
				<div>
					<button (click)="calculateLend()"
						class="invest-btn mt-4 mb-2">
						{{"AboutMe.Actionbtn" | translate}}
					</button>
				</div>
				<!-- <div class="input-range-label">
						<span>1</span>
						<span>24</span>
					</div> -->
			</div>
			<div class="col-md-4">
				<app-card-lend [amount]="monto"
					[time]="plazo"
					[percentage]="totalPercentage"
					[totalAmount]="totalLend"
					[timeString]="timeString"
					[total]="totalAmount"></app-card-lend>
			</div>
		</div>
	</div>
</section>
